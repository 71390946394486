import React from 'react';
import PropTypes from 'prop-types';

import Link, { propTypes as linkPropTypes } from '../../../01_atoms/Link';
import Button from '../../../01_atoms/Button';
import Tagline from '../../../01_atoms/Tagline';
import {
  behaviorSettingsProps,
  generateClassNameByBehaviorSettings,
} from '../../../../utils/behaviorSettings';

import styles from './index.module.scss';

const BBFeaturedEvent = ({
  image,
  label = '',
  title = '',
  eventName,
  description = '',
  button,
  behaviorSettings = null,
  uuid = null,
}) => (
  <div
    className={`bb bb-featured-event ${
      styles['bb-featured-event']
    } ${generateClassNameByBehaviorSettings(behaviorSettings)}`}
    id={uuid}
  >
    <div className="container">
      {title && <h2 className="rockitt">{title}</h2>}

      <Link {...button.nextLink}>
        <a href={button.nextLink.url}>
          <div className="row">
            <div className="col-12 col-md-6">
              <div
                className="image"
                style={{ backgroundImage: `url(${image.url})` }}
                title={image.alt}
              />
            </div>
            <div className="col-12 col-md-6">
              <div className="content">
                {label && <Tagline className="label" label={label} />}

                <h3 className="title">{eventName}</h3>

                {description && <h3 className="description">{description}</h3>}

                <Button
                  tag="button"
                  type="primary"
                  className="bb-featured-event--button"
                  withArrow
                  size="extra-large"
                >
                  {button.label}
                </Button>
              </div>
            </div>
          </div>
        </a>
      </Link>
    </div>
  </div>
);

BBFeaturedEvent.propTypes = {
  title: PropTypes.string,
  image: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
  }).isRequired,
  label: PropTypes.string,
  eventName: PropTypes.string.isRequired,
  description: PropTypes.string,
  button: PropTypes.shape({
    label: PropTypes.string,
    nextLink: PropTypes.shape(linkPropTypes),
  }).isRequired,
  behaviorSettings: behaviorSettingsProps,
  uuid: PropTypes.string,
};

export default BBFeaturedEvent;
